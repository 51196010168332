import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"

import { SidebarContents } from "../../../components/modules/sidebar-contents/sidebar-contents"

export const FactSheetContents = ({ className, content }) => {
  const [headers, setHeaders] = useState([])

  useEffect(() => {
    const headers = content
      .split("\n")
      .filter(line => line.startsWith("## "))
      .map(header => header.substr(3))

    setHeaders(headers)
  }, [])

  return <SidebarContents headers={headers} className={className} />
}

FactSheetContents.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
}
