import React, { useEffect, useState, useRef } from "react"
import MarkdownView from "react-showdown"

import { graphql, Link } from "gatsby"
import { inject, observer } from "mobx-react"

import { Wrapper } from "../../components/base/wrapper/wrapper"
import { SEO } from "../../components/base/seo/seo"
import { Alert } from "../../components/modules/alert/alert"
import { FactSheetContents } from "./fact-sheet-contents/fact-sheet-contents"
import { FactSheetPrint } from "./fact-sheet-print/fact-sheet-print"
import { FactSheetRegionCallout } from "./fact-sheet-region-callout/fact-sheet-region-callout"
import {
  isInViewport,
  isBrowser,
  redirectToAppropriateRegionIfEw,
} from "../../helpers/helpers"

import "./fact-sheet.scss"

export default inject("regionStore")(
  observer(({ regionStore, data }) => {
    const {
      seoTitle,
      title,
      description,
      canonicalUrl,
      validInEngland,
      alternateEnglandSlug,
      alternateScotlandSlug,
      factSheetContent,
    } = data.wagtail.page

    const { isEngland, isScotland } = regionStore

    const [isScrolled, _setIsScrolled] = useState(false)

    const isScrolledRef = useRef(isScrolled)
    const setIsScrolled = data => {
      isScrolledRef.current = data
      _setIsScrolled(data)
    }

    let footer = null

    useEffect(() => {
      redirectToAppropriateRegionIfEw(isScotland)

      window.addEventListener("scroll", listener, false)

      footer = document.getElementById("footer")

      const body = document.getElementsByClassName("fact-sheet-body")[0]
      body.innerHTML = parseFactSheetContent(body.innerHTML)
    }, [])

    const listener = () => {
      const scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop

      const isScrolled = isScrolledRef.current

      if (isInViewport(footer)) {
        if (isScrolled) {
          setIsScrolled(false)
        }
        return
      }

      if (!isScrolled && scrollTop > 1500) {
        setIsScrolled(true)
      } else if (isScrolled && scrollTop <= 1500) {
        setIsScrolled(false)
      }
    }

    const scrollToTop = () => {
      if ("scrollRestoration" in history) {
        history.scrollRestoration = "manual"
      }
      isBrowser &&
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
    }

    const parseFactSheetContent = factSheetContent =>
      factSheetContent
        .replace("<table>", "<div class='table-wrapper'><table>")
        .replace("</table>", "</table></div>")

    const alternateSlug =
      validInEngland && alternateScotlandSlug
        ? alternateScotlandSlug
        : !validInEngland && alternateEnglandSlug
        ? alternateEnglandSlug
        : ""

    return (
      <Wrapper className={`fact-sheet ${validInEngland ? "ew" : "s"}`}>
        <SEO
          title={`Fact Sheet - ${seoTitle}`}
          description={description}
          canonical={canonicalUrl}
        />

        {!validInEngland && isEngland && (
          <Alert
            title="This Fact Sheet is not relevant in your region"
            description={
              <>
                Sorry, this Fact Sheet is not relevant in your region. Please go
                to the <Link to="/fact-sheet-library">Fact Sheet library</Link>{" "}
                to see Fact Sheets relevant in your region.
              </>
            }
          />
        )}

        <div className="fact-sheet-wrapper">
          <FactSheetPrint />

          <div className="col-12 col-md-8 offset-md-3 mb-3 fact-sheet-title">
            <h1>{title}</h1>

            <FactSheetRegionCallout
              currentLocation={validInEngland ? "England & Wales" : "Scotland"}
              alternateLocation={
                !validInEngland ? "England & Wales" : "Scotland"
              }
              alternateSlug={alternateSlug}
            />
          </div>

          <FactSheetContents
            className="col-12 col-md-3 d-print-none fact-sheet-contents"
            content={factSheetContent}
          />

          <MarkdownView
            className="col-12 offset-md-3 col-md-8 fact-sheet-body"
            markdown={factSheetContent}
            options={{
              customizedHeaderId: true,
              tables: true,
              tablesHeaderId: true,
              tasklists: true,
              openLinksInNewWindow: true,
            }}
          />
          <button
            onClick={scrollToTop}
            className={`
            btn 
            btn-primary 
            fact-sheet-back-to-top 
            ${!isScrolled ? "is-hidden" : ""}
          `}
          >
            Back to top
          </button>
        </div>
      </Wrapper>
    )
  })
)

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on FactSheet {
          id
          seoTitle
          title
          description
          canonicalUrl
          alternateEnglandSlug
          alternateScotlandSlug
          validInEngland
          validInScotland
          factSheetContent
        }
      }
    }
  }
`
