import React from "react"

import Logo from "../../../images/logo.svg"

import "./fact-sheet-print.scss"

export const FactSheetPrint = () => (
  <div className="d-none d-print-inline-block fact-sheet-print">
    <img src={Logo} className="fact-sheet-print-logo" alt="" />
  </div>
)
