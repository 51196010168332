import React from "react"

import { inject, observer } from "mobx-react"

import PropTypes from "prop-types"
import { navigate } from "gatsby"

import "./fact-sheet-region-callout.scss"

export const FactSheetRegionCallout = inject("regionStore")(
  observer(
    ({ regionStore, currentLocation, alternateLocation, alternateSlug }) => {
      const handleFactSheetSwitch = e => {
        e.preventDefault()

        regionStore.switchCurrentRegion()
        navigate(`/fact-sheet-library/${alternateSlug}`)
      }

      return (
        <div className="fs-callout3 fact-sheet-region-callout">
          <p>
            This fact sheet covers&nbsp;
            <strong>{currentLocation}</strong>
            .&nbsp;
            {alternateSlug ? (
              <span>
                We also have a version for&nbsp;
                <a href="#" onClick={handleFactSheetSwitch}>
                  {alternateLocation}
                </a>
                &nbsp;if you need it.
              </span>
            ) : (
              <>
                You will need different advice if you live in{" "}
                <a href="#" onClick={handleFactSheetSwitch}>
                  {alternateLocation}
                </a>
                .
              </>
            )}
          </p>
        </div>
      )
    }
  )
)

FactSheetRegionCallout.propTypes = {
  currentLocation: PropTypes.string,
  alternateLocation: PropTypes.string,
  alternateSlug: PropTypes.string,
}
